define("@outdoorsyco/ember-shared-data/serializers/user", ["exports", "ember-data", "moment", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _moment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class UserSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        taxRates: {
          serialize: 'ids',
          deserialize: 'ids'
        },
        role: {
          serialize: 'ids',
          deserialize: 'records'
        }
      });
    }

    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }

    keyForRelationship(key, type, method) {
      if (key === 'role' && method !== 'serialize') {
        return key;
      }

      return super.keyForRelationship(key, type, method);
    }

    normalize(typeClass, hash) {
      if (hash.attributes) {
        let id = hash.id;
        hash = hash.attributes;
        hash.id = id;
      }

      if (hash.data && hash.data.attributes) {
        let id = hash.data.id;
        hash = hash.data.attributes;
        hash.id = id;
      }

      if (hash.owned_by_id) {
        hash.outdoorsy_user = false;
        hash.can_edit = true;
        hash.owned_by = hash.owned_by_id;
      } else {
        hash.outdoorsy_user = true;
        delete hash.owned_by_id;
      }

      Ember.assign(hash, hash.profile);
      /**
       * The backend API gives back different data formats depending on if
       * it is a GET or PATCH or done via the search API. This attempts to
       * deal with this.
       */

      if (hash.bio) {
        hash.years_owned = hash.bio.years_owned;
        hash.years_rving = hash.bio.years_rving;
        hash.description = hash.bio.description;
      }

      hash.address = hash.address || {};
      hash.street = hash.address.street;
      hash.street1 = hash.address.street_etc;
      hash.city = hash.address.city;
      hash.state = hash.address.state;
      hash.zip = hash.address.zip;
      hash.county = hash.address.county;
      hash.country = hash.address.country;
      hash.locale = hash.locale || {};
      hash.locale_base_currency = hash.locale.base_currency;
      hash.locale_date_format = hash.locale.date_format;
      hash.locale_language = hash.locale.language;
      hash.locale_time_format = hash.locale.time_format;
      hash.locale_timezone = hash.locale.timezone;
      hash.locale_distance_unit = hash.locale.distance_unit;
      hash.locale_length_unit = hash.locale.length_unit;
      hash.locale_weight_unit = hash.locale.weight_unit;
      hash.locale_liquid_unit = hash.locale.liquid_unit;
      hash.extra = hash.extra || {};
      hash.rv_style = hash.extra.rvStyle;
      hash.rv_destinations = hash.extra.rvDestinations;
      hash.verifications = hash.verifications || {};
      hash.identity_verified = hash.verifications.score;
      hash.verification_upload_link = hash.verifications.verification_upload_link;
      hash.email_verified = hash.verifications.email;
      hash.phone_verified = hash.verifications.phone;
      let alreadySentMsg = hash.verifications.has_sent_message_without_phone_verified;
      hash.has_sent_message_without_phone_verified = alreadySentMsg;
      let countryForPayment = hash.country || 'US';
      let bankCountry = hash.bank_country || 'US';
      hash.payments = hash.payments || {};
      hash.payment_processing_info = hash.payment_processing_info || [];
      hash.payment_processing_info.some(payment => {
        let hasBank = payment.can_accept_payments && bankCountry === payment.payment_processor.country;

        if (hasBank) {
          countryForPayment = payment.payment_processor.country;
        }

        return hasBank;
      });
      hash.links = hash.links || {};
      hash.links.cards = `/v0/users/${hash.id}/cards?country=${countryForPayment}`;
      hash.links.specialHours = `/v0/special-hours?order_by=-location_id&owner_id=${hash.id}`;
      hash.links.localizedContent = `/v0/localized-content?object_type=users&object_id=${hash.id}`;

      if (hash.business) {
        hash.business_name = hash.business.name;
        hash.business_description = hash.business.description;
        hash.business_website = hash.business.website;
        hash.business_phone = hash.business.phone;
        hash.house_rules = hash.business.house_rules;
        hash.cancel_text = hash.business.cancel_text;
        hash.cancel_policy = hash.business.cancel_policy;
        hash.cancel_policy_combo_bookings = hash.business.cancel_policy_combo_bookings;
        hash.terms_of_service = hash.business.terms_of_service;
        hash.consigned_pay_pct = hash.business.consigned_pay_pct;
        hash.consigned_pay_pct_generator = hash.business.consigned_pay_pct_generator;
        hash.consigned_pay_pct_mileage = hash.business.consigned_pay_pct_mileage;
        hash.consigned_pay_pct_insurance_fee = hash.business.consigned_keep_pct_insurance_fee;
        hash.consigned_pay_pct_cc_fee = hash.business.consigned_keep_pct_cc_fee;
        hash.consigned_pay_addon_pct_fee = hash.business.consigned_keep_pct_addon_fee;
        hash.checkout_questions = hash.business.checkout_questions;
        hash.segment_token = hash.business.segment_token;
        hash.google_analytics_id = hash.business.google_analytics_id;
        hash.google_tag_manager_id = hash.business.google_tag_manager_id;
        hash.localized_languages = hash.business.localized_languages;
        hash.outdoorsy_display_name = hash.business.outdoorsy_display_name;
      }

      if (hash.booking_options) {
        hash.booking_deposit_percentage = hash.booking_options.deposit_percentage;
        hash.booking_minimum_deposit = hash.booking_options.minimum_deposit;
        hash.final_payment_due_days = hash.booking_options.final_payment_due_days;
        hash.security_deposit_due_days = hash.booking_options.security_deposit_due_days;
        hash.minimum_days = hash.booking_options.minimum_days;
        hash.request_less_than_minimum_days = hash.booking_options.request_less_than_minimum_days;
        hash.require_booking_time = hash.booking_options.require_booking_time;
        hash.instant_book = hash.booking_options.instant_book;
        hash.insurance_renter_adjustable = hash.booking_options.insurance_renter_adjustable;
        hash.custom_insurance_text = hash.booking_options.custom_insurance_text;
        hash.disable_coachnet = hash.booking_options.disable_coachnet;
        hash.disable_instamatch = hash.booking_options.disable_instamatch;
        hash.require_driver_checks = hash.booking_options.require_driver_checks;
        hash.turnaround_days_after = hash.booking_options.turnaround_days_after;
        hash.instant_book_leeway = hash.booking_options.instant_book_leeway;
        hash.insurance_coverage = hash.booking_options.insurance_coverage;
        hash.use_day_pricing = hash.booking_options.use_day_pricing;
        hash.use_tax_inclusive_pricing = hash.booking_options.use_tax_inclusive_pricing;
        hash.variable_fees_upfront = hash.booking_options.variable_fees_upfront;
        hash.coachnet_premium = hash.booking_options.coachnet_premium;
        hash.bid_quality_preference = hash.booking_options.bid_quality_preference || 0.25;
        hash.default_insurance_bundle_tier = hash.booking_options.default_insurance_bundle_tier;
        let tax_rate_ids = hash.booking_options.tax_rate_ids || [];
        hash.tax_rates = tax_rate_ids;

        if (hash.booking_options.tax_rate_id !== 0) {
          hash.tax_rate_id = hash.booking_options.tax_rate_id;
        }

        if (Ember.isPresent(hash.booking_options.from_time)) {
          hash.from_time = hash.booking_options.from_time;
        }

        if (Ember.isPresent(hash.booking_options.to_time)) {
          hash.to_time = hash.booking_options.to_time;
        }
      }

      if (hash.permissions) {
        hash.can_process_payments = hash.permissions.process_payments;
        hash.deposit_reservation = hash.permissions.deposit_reservation;
      }

      if (hash.notifications && hash.notifications.sms) {
        hash.booking_sms = hash.notifications.sms.bookings;
        hash.message_sms = hash.notifications.sms.messages;
      }

      delete hash.profile;
      delete hash.verifications;
      delete hash.extra;
      delete hash.address;
      delete hash.bio;
      delete hash.payments;
      delete hash.business;
      delete hash.locale;
      delete hash.notifications;
      hash.card_token = null;
      return super.normalize(typeClass, hash);
    } // Model -> what the API expects


    serialize(record, options) {
      const data = super.serialize(record, options); // these values are removed at the end
      // normally because they've been transformed into something else already

      const deletable = ['account_type', 'created', 'updated', 'street', 'city', 'state', 'zip', 'yearsRving', 'street1', 'credits', 'fun_facts', 'interests', 'kids_pets', 'rv_style', 'card_token', 'rv_destinations', 'business_phone', 'business_name', 'business_description', 'business_website', 'booking_deposit_minimum', 'booking_deposit_percentage', 'cancel_text', 'house_rules', 'cancel_policy', 'cancel_policy_combo_bookings', 'terms_of_service', 'earned_credits', 'ssn', 'final_payment_due_days', 'security_deposit_due_days', 'verification_upload_link', 'tax_rate_id', 'minimum_days', 'instant_book', 'tunaround_days_after', 'instant_book_leeway', 'insurance_coverage', 'use_day_pricing', 'use_tax_inclusive_pricing', 'default_insurance_bundle_tier', 'consigned_pay_pct', 'consigned_pay_pct_generator', 'consigned_pay_pct_mileage', 'consigned_pay_pct_cc_fee', 'consigned_pay_pct_insurance_fee', 'consigned_pay_pct_cc_fee', 'checkout_questions', 'insurance_renter_adjustable', 'disable_coachnet', 'disable_instamatch', 'require_driver_checks', 'variable_fees_upfront', 'coachnet_premium', 'reconnect_bank', 'deposits_disabled', 'custom_insurance_text', 'localized_languages', 'request_less_than_minimum_days', 'require_booking_time'];

      if (data.birthdate) {
        data.birthdate = (0, _moment.default)(data.birthdate).format('YYYY-MM-DD');
      }

      if (data.card_token) {
        data.payment_info = {
          stripe_token: data.card_token
        };
      }

      data.bio = {
        years_rving: data.years_rving,
        years_owned: data.years_owned,
        description: data.description
      };
      data.address = {
        street: data.street,
        street_etc: data.street1,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country
      };
      data.locale = {
        timezone: data.locale_timezone,
        base_currency: data.locale_base_currency,
        date_format: data.locale_date_format,
        language: data.locale_language,
        time_format: data.locale_time_format,
        distance_unit: data.locale_distance_unit,
        length_unit: data.locale_length_unit,
        weight_unit: data.locale_weight_unit,
        liquid_unit: data.locale_liquid_unit
      };
      data.business = {
        name: data.business_name,
        website: data.business_website,
        description: data.business_description,
        phone: data.business_phone,
        cancel_text: data.cancel_text,
        house_rules: data.house_rules,
        cancel_policy: data.cancel_policy,
        cancel_policy_combo_bookings: data.cancel_policy_combo_bookings,
        terms_of_service: data.terms_of_service,
        consigned_pay_pct: data.consigned_pay_pct,
        consigned_pay_pct_mileage: data.consigned_pay_pct_mileage,
        consigned_pay_pct_generator: data.consigned_pay_pct_generator,
        consigned_keep_pct_cc_fee: data.consigned_pay_pct_cc_fee,
        consigned_keep_pct_insurance_fee: data.consigned_pay_pct_insurance_fee,
        consigned_keep_pct_addon_fee: data.consigned_pay_addon_pct_fee,
        checkout_questions: data.checkout_questions,
        segment_token: data.segment_token,
        google_analytics_id: data.google_analytics_id,
        google_tag_manager_id: data.google_tag_manager_id,
        localized_languages: data.localized_languages,
        outdoorsy_display_name: data.outdoorsy_display_name
      };
      let tax_rate_ids = data.tax_rates || [];
      data.tax_rate_ids = tax_rate_ids.map(tr => parseInt(tr));
      delete data.tax_rates;
      data.booking_options = {
        minimum_deposit: data.booking_minimum_deposit,
        deposit_percentage: data.booking_deposit_percentage,
        final_payment_due_days: data.final_payment_due_days,
        security_deposit_due_days: data.security_deposit_due_days,
        tax_rate_id: data.tax_rate_id,
        tax_rate_ids: data.tax_rate_ids,
        minimum_days: data.minimum_days,
        request_less_than_minimum_days: data.request_less_than_minimum_days,
        require_booking_time: data.require_booking_time,
        instant_book: data.instant_book,
        turnaround_days_after: data.turnaround_days_after,
        instant_book_leeway: data.instant_book_leeway,
        insurance_renter_adjustable: data.insurance_renter_adjustable,
        custom_insurance_text: data.custom_insurance_text,
        disable_coachnet: data.disable_coachnet,
        disable_instamatch: data.disable_instamatch,
        require_driver_checks: data.require_driver_checks,
        insurance_coverage: data.insurance_coverage,
        use_day_pricing: data.use_day_pricing,
        use_tax_inclusive_pricing: data.use_tax_inclusive_pricing,
        variable_fees_upfront: data.variable_fees_upfront,
        coachnet_premium: data.coachnet_premium,
        bid_quality_preference: data.bid_quality_preference,
        default_insurance_bundle_tier: data.default_insurance_bundle_tier
      }; // both turn around times the same, cause we think they should be for the interface

      if (data.from_time) {
        data.booking_options.from_time = data.from_time;
      }

      delete data.from_time;

      if (data.to_time) {
        data.booking_options.to_time = data.to_time;
      }

      delete data.to_time;
      data.extra = {
        kidsPets: data.kids_pets,
        rvStyle: data.rv_style,
        interests: data.interests,
        rvDestinations: data.rv_destinations
      };
      deletable.forEach(n => delete data[n]); // QST number is defined in metadata when read, but API expects it in root object
      // outside of metadata..

      data.qst_number = data.metadata.qst_number;
      return data;
    }

  }

  _exports.default = UserSerializer;
});
define("@outdoorsyco/ember-shared-data/serializers/booking", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class BookingSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        proposals: {
          deserialize: 'records',
          serialize: false
        },
        lineItems: {
          deserialize: 'records',
          serialize: false
        },
        premiumServices: {
          deserialize: 'records',
          serialize: false
        },
        insuranceBundle: {
          deserialize: 'records',
          serialize: false
        },
        securityDepositCard: {
          deserialize: 'records',
          serialize: false
        },
        rentalChargeCard: {
          deserialize: 'records',
          serialize: false
        },
        paymentCountry: {
          serialize: false
        },
        insurancePlan: {
          serialize: false
        },
        specialInsuranceCase: {
          serialize: false
        },
        waiverSummary: {
          serialize: false
        },
        deliveryUsageItem: {
          deserialize: 'records',
          serialize: 'ids'
        },
        mileageUsageItem: {
          deserialize: 'records',
          serialize: 'ids'
        },
        generatorUsageItem: {
          deserialize: 'records',
          serialize: 'ids'
        },
        transactions: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    serializeAttribute(snapshot, json, key) {
      if (key === 'insurancePlan') {
        let insurancePlan = snapshot.attr('insurancePlan');

        if (insurancePlan !== null && insurancePlan !== void 0 && insurancePlan.id) {
          json['insurance_plan_id'] = insurancePlan.id;
        }
      }

      super.serializeAttribute(...arguments);
    }

    keyForRelationship(key, relationship) {
      /**
       * For some reason the transactions relationship is coming
       * in as undefined.
       */
      if (!relationship) {
        return Ember.String.underscore(key);
      }

      return super.keyForRelationship(...arguments);
    } // Model -> what the API expects


    serialize(record, options) {
      const data = super.serialize(record, options);

      if (data.delivery_usage_item_id) {
        data.delivery_usage_item_id = parseInt(data.delivery_usage_item_id);
      }

      if (data.mileage_usage_item_id) {
        data.mileage_usage_item_id = parseInt(data.mileage_usage_item_id);
      }

      if (data.generator_usage_item_id) {
        data.generator_usage_item_id = parseInt(data.generator_usage_item_id);
      }

      delete data.payment_processor_id;
      data.bid_id = data.quote_request_id;
      delete data.quote_request_id;

      if (data.new_renter_first_name || data.new_renter_email) {
        data.renter = {
          first_name: data.new_renter_first_name,
          last_name: data.new_renter_last_name,
          email: data.new_renter_email,
          phone: data.new_renter_phone,
          country: data.new_renter_country || 'US',
          locale: data.new_renter_locale || '',
          disable_sms: data.new_renter_disable_sms,
          birthdate: data.new_renter_birthdate,
          signup_source: data.new_renter_signup_source
        };
        delete data.new_renter_first_name;
        delete data.new_renter_last_name;
        delete data.new_renter_email;
        delete data.new_renter_phone;
        delete data.new_renter_country;
        delete data.new_renter_locale;
        delete data.new_renter_disable_sms;
        delete data.new_renter_birthdate;
        delete data.new_renter_signup_source;
      } // consigned owner support


      data.consignment = {
        payout_id: data.consigned_payout_id,
        pay_pct_generator: data.consigned_generator_percentage,
        pay_pct_mileage: data.consigned_mileage_percentage,
        pay_amt_total: data.consigned_total_amount,
        keep_amt_cc_fee: data.consigned_cc_fee_amount,
        keep_amt_insurance_fee: data.consigned_insurance_fee_amount
      };
      delete data.consigned_generator_amount;
      delete data.consigned_generator_percentage;
      delete data.consigned_mileage_amount;
      delete data.consigned_mileage_percentage;
      delete data.consigned_payout_id;
      delete data.consigned_cc_fee_amount;
      delete data.consigned_insurance_fee_amount;
      data.locale = {
        distance_unit: data.locale_distance_unit,
        base_currency: data.locale_base_currency
      };
      delete data.locale_distance_unit;
      delete data.locale_base_currency;
      data.items = data.addons; // mileage, deliver, and generator

      data.mileage = {
        departure: data.mileage_departure,
        return: data.mileage_return,
        estimated: data.mileage_estimated,
        actual: data.mileage_actual,
        estimated_fee: data.mileage_estimated_fee,
        suggested_fee: data.mileage_suggested_fee
      };
      data.delivery = {
        departure: data.delivery_departure,
        return: data.delivery_return,
        estimated: data.delivery_estimated,
        actual: data.delivery_actual,
        estimated_fee: data.delivery_estimated_fee,
        suggested_fee: data.delivery_suggested_fee,
        renter_comment: data.delivery_renter_comment,
        stationary: data.delivery_stationary
      };
      data.generator = {
        departure: data.generator_departure,
        return: data.generator_return,
        estimated: data.generator_estimated,
        actual: data.generator_actual,
        estimated_fee: data.generator_estimated_fee,
        suggested_fee: data.generator_suggested_fee
      };
      delete data.mileage_departure;
      delete data.mileage_return;
      delete data.mileage_estimated;
      delete data.mileage_actual;
      delete data.mileage_estimated_fee;
      delete data.mileage_suggested_fee;
      delete data.delivery_departure;
      delete data.delivery_return;
      delete data.delivery_estimated;
      delete data.delivery_actual;
      delete data.delivery_estimated_fee;
      delete data.delivery_suggested_fee;
      delete data.delivery_renter_comment;
      delete data.delivery_stationary;
      delete data.generator_departure;
      delete data.generator_return;
      delete data.generator_estimated;
      delete data.generator_actual;
      delete data.generator_estimated_fee;
      delete data.generator_suggested_fee;
      data.details = this._handleDetails(data);
      delete data.is_festival_travel;
      delete data.has_pets;

      if (!data.stripe_token) {
        delete data.stripe_token;
      }

      return data;
    }

    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (Ember.isArray(payload)) {
        return this.normalizeArrayResponse(store, primaryModelClass, payload, id, requestType);
      }

      return this.normalizeSingleResponse(store, primaryModelClass, payload, id, requestType);
    } // API -> what the model expects


    normalize(typeClass, data) {
      data.renter_accepted = data.renter_approved;
      data.owner_accepted = data.owner_approved;
      data.links = data.links || {};
      data.links.drivers = `/v0/bookings/${data.id}/drivers`;
      data.links.proposals = `/v0/bookings/${data.id}/proposals`;
      data.links.passengers = `/v0/bookings/${data.id}/passengers`;
      data.links.expressCheckout = `/v0/bookings/${data.id}/default-selections`;
      data.links.handoff = `/v0/handoffs?booking_id=${data.id}`;

      if (Object.prototype.hasOwnProperty.call(data, 'instamatch')) {
        data.is_instamatch = data.instamatch;
      } else {
        data.is_instamatch = data.bid_id && data.owner_id === data.created_by_id ? true : false;
      }

      if (Ember.isArray(data.proposals)) {
        data.proposals.map(p => p.type = 'proposal');
      } // we only want to do this if it's an array.  If not, just ignore it!


      if (Ember.isArray(data.items)) {
        data.line_items = data.items;
      }

      if (Ember.isArray(data.services)) {
        data.premium_services = data.services;
      }

      data.owner_credits = data.owner_credits_applied;
      data.renter_fee = data.service_fee;
      data.renter_fee_tax_rates = data.service_fee_tax_rates;
      data.renter_credits = data.renter_credits_applied;
      data.stripe_token = null;

      if (data.bid_id) {
        data.quote_request_id = data.bid_id;
      }

      if (data.location_id === 0) {
        delete data.location_id;
      }

      let pp = data.payment_processor;

      if (pp && pp.id) {
        data.payment_processor_id = pp.id;
      }

      data.insurance_plan = data.insurance_plan_id;
      delete data.insurance_plan_id; // we ALWAYS set this to false, cause we're getting a resonse so we no longer want to save it
      // if it's currently set to true

      data.recalculate = false; // reset message to empty string, safe guard against multiple posts

      data.message = null; // handle consignment

      if (data.consignment) {
        data.consigned_payout_id = data.consignment.payout_id;
        data.consigned_mileage_percentage = data.consignment.pay_pct_mileage;
        data.consigned_generator_percentage = data.consignment.pay_pct_generator;
        data.consigned_total_amount = data.consignment.pay_amt_total;
        data.consigned_cc_fee_amount = data.consignment.keep_amt_cc_fee;
        data.consigned_insurance_fee_amount = data.consignment.keep_amt_insurance_fee;
      }

      if (data.locale) {
        data.locale_distance_unit = data.locale.distance_unit;
        data.locale_base_currency = data.locale.base_currency;
      }

      if (data.mileage) {
        data.mileage_departure = data.mileage.departure;
        data.mileage_return = data.mileage.return;
        data.mileage_estimated = data.mileage.estimated;
        data.mileage_actual = data.mileage.actual;
        data.mileage_estimated_fee = data.mileage.estimated_fee;
        data.mileage_suggested_fee = data.mileage.suggested_fee;
      }

      if (data.delivery) {
        data.delivery_departure = data.delivery.departure;
        data.delivery_return = data.delivery.return;
        data.delivery_estimated = data.delivery.estimated;
        data.delivery_actual = data.delivery.actual;
        data.delivery_estimated_fee = data.delivery.estimated_fee;
        data.delivery_suggested_fee = data.delivery.suggested_fee;
        data.delivery_renter_comment = data.delivery.renter_comment;
        data.delivery_stationary = data.delivery.stationary || false;
      }

      if (data.generator) {
        data.generator_departure = data.generator.departure;
        data.generator_return = data.generator.return;
        data.generator_estimated = data.generator.estimated;
        data.generator_actual = data.generator.actual;
        data.generator_estimated_fee = data.generator.estimated_fee;
        data.generator_suggested_fee = data.generator.suggested_fee;
      }

      if (data.security_deposit_card_id && data.security_deposit_card_id !== 0) {
        data.security_deposit_card = {
          id: data.security_deposit_card_id,
          owner_id: data.renter_id
        };
      }

      if (data.rental_charge_card_id && data.rental_charge_card_id !== 0) {
        data.rental_charge_card = {
          id: data.rental_charge_card_id,
          owner_id: data.renter_id
        };
        data.has_cards = true;
      }

      delete data.rental_charge_card_id;
      delete data.security_deposit_card_id;
      let details = data.details || {};

      if (typeof details !== 'object') {
        details = {};
      }

      data.has_pets = details.has_pets || false;
      data.is_festival_travel = details.is_festival_travel || false;
      delete data.mileage;
      delete data.delivery;
      delete data.generator;
      delete data.renter_approved;
      delete data.owner_approved;
      delete data.items;
      delete data.services;
      delete data.service_fee;
      return super.normalize(typeClass, data);
    }

    _handleDetails(data) {
      let details = {
        has_pets: data.has_pets,
        is_festival_travel: data.is_festival_travel
      };
      return Ember.assign({}, details, data.details);
    }

  }

  _exports.default = BookingSerializer;
});
define("@outdoorsyco/ember-shared-data/components/loading-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+zujBDUe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"class\",[29,[[22,\"buttonClass\"],\" loading\"]]],[10,\"disabled\",\"\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-spin fa-refresh\"],[8],[9],[0,\" \"],[1,[22,\"loadingText\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[29,[[22,\"buttonClass\"]]]],[12,\"disabled\",[22,\"disabled\"]],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"submit\"]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"]]]],[8],[9],[0,\" \"]],\"parameters\":[]},null],[7,\"span\",true],[10,\"class\",\"btn-label-text\"],[8],[1,[22,\"buttonText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/loading-button/template.hbs"
    }
  });

  _exports.default = _default;
});
define("@outdoorsyco/ember-shared-data/serializers/rental", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/utils/rental-features", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _emberData, _rentalFeatures, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  class RentalSerializer extends _application.default.extend(EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        taxRates: {
          serialize: 'ids',
          deserialize: 'ids'
        },
        primaryImage: {
          deserialize: 'records',
          serialize: false
        },
        images: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    keyForRelationship(key, relationship) {
      // images are embedded and therefor are NOT sent with an ID
      if (key === 'images' || key === 'primaryImage') {
        return key;
      }

      if (key === 'vehicleOwner') {
        return 'vehicle_owner_id';
      }

      return super.keyForRelationship(key, relationship);
    }

    appConfig() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }

    pricingHost() {
      let config = this.appConfig() || {};
      let app = config.APP || {};
      return app.pricingHost || '';
    }

    normalize(typeClass, hash) {
      hash.insurance_plan = hash.insurance_plan_id;
      hash.owner_identifier = hash.owner_id;
      hash.location = hash.home;
      hash.links = hash.links || {};
      hash.links.price = `${this.pricingHost()}/prices/${hash.id}`;
      hash.links.lineItems = `/v0/items?rental_id=${hash.id}`;
      hash.links.tags = `/v0/rentals/${hash.id}/tags`;
      hash.links.localizedContent = `/v0/localized-content?object_type=rentals&object_id=${hash.id}`; // TODO: Sorry.

      hash.bonus = hash.owner_id === 17075;

      if (Ember.isPresent(hash.price)) {
        hash.security_deposit = hash.price.security_deposit;
        hash.can_charge_security_deposit = hash.price.can_charge_security_deposit;
      }

      if (Ember.isPresent(hash.settings)) {
        hash.show_renter_submitted_photos = hash.settings.show_renter_submitted_photos;
      }

      if (!hash.settings) {
        hash.settings = {};
      } // active price changes based on current season.  Should never be blank, but fallback just in case


      if (Ember.isPresent(hash.active_price) || Ember.isPresent(hash.price)) {
        hash.active_price = hash.active_price || {};
        hash.price = hash.price || {};
        hash.active_price_day = hash.active_price.day || hash.price.day;
        hash.active_price_day_base = hash.active_price.base_day;
        hash.active_price_day_tax = hash.active_price.tax;
        hash.active_price_week = hash.active_price.week;
        hash.active_price_month = hash.active_price.month;
      }

      if (Ember.isPresent(hash.active_options)) {
        hash.active_instant_book = hash.active_options.instant_book;
        hash.active_cancel_policy = hash.active_options.cancel_policy;
        hash.active_cancel_policy_combo_bookings = hash.active_options.cancel_policy_combo_bookings;
        hash.active_use_day_pricing = hash.active_options.use_day_pricing;
        hash.active_use_tax_inclusive_pricing = hash.active_options.use_tax_inclusive_pricing;
        hash.active_minimum_nights = hash.active_options.minimum_nights;
      }

      if (Ember.isPresent(hash.vehicle)) {
        hash.vehicle_make = hash.vehicle.make;
        hash.vehicle_model = hash.vehicle.model;
        hash.vehicle_trim = hash.vehicle.trim;
        hash.vehicle_year = hash.vehicle.year;
        hash.vehicle_length = hash.vehicle.length;
        hash.vehicle_vin = hash.vehicle.vin;
        hash.vehicle_class = hash.vehicle.class;
        hash.vehicle_box_length = hash.vehicle.box_length;
        hash.vehicle_width = hash.vehicle.width;
        hash.vehicle_length_with_hitch = hash.vehicle.length_with_hitch;
        hash.vehicle_height = hash.vehicle.height;
        hash.vehicle_license_plate = hash.vehicle.license_plate;
        hash.vehicle_license_plate_state = hash.vehicle.license_plate_state;
        hash.vehicle_license_plate_country = hash.vehicle.license_plate_country;
        hash.vehicle_replacement_value = hash.vehicle.replacement_value;
        hash.vehicle_dry_weight = hash.vehicle.dry_weight;
        hash.vehicle_current_mileage = hash.vehicle.current_mileage;
        hash.vehicle_current_generator = hash.vehicle.current_generator;
        hash.vehicle_amps = hash.vehicle.amps;
        hash.vehicle_gvwr = hash.vehicle.gvwr;
        hash.vehicle_unit_of_measure = hash.vehicle.unit_of_measure;
      }

      if (Ember.isPresent(hash.consignment)) {
        hash.vehicle_owner_id = hash.consignment.owner_id;
        hash.consigned_pay_pct = hash.consignment.pay_pct;
        hash.consigned_pay_pct_mileage = hash.consignment.pay_pct_mileage;
        hash.consigned_pay_pct_generator = hash.consignment.pay_pct_generator;
        hash.consigned_pay_pct_cc_fee = hash.consignment.keep_pct_cc_fee;
        hash.consigned_pay_pct_insurance_fee = hash.consignment.keep_pct_insurance_fee;
      }

      if (Ember.isPresent(hash.locale)) {
        hash.locale_base_currency = hash.locale.base_currency;
        hash.locale_length_unit = hash.locale.length_unit;
        hash.locale_weight_unit = hash.locale.weight_unit;
        hash.locale_liquid_unit = hash.locale.liquid_unit;
        hash.locale_distance_unit = hash.locale.distance_unit;
      }

      if (!hash.location) {
        hash.location = {};
      }

      if (hash.primary_image_id) {
        hash.primaryImage = {
          id: hash.primary_image_id,
          url: hash.primary_image_url
        };
      }

      let tax_rate_ids = hash.tax_rate_ids || [];
      hash.tax_rates = tax_rate_ids;

      if (hash.tax_rate_id === 0) {
        delete hash.tax_rate_id;
      }

      Ember.assign(hash, hash.features);

      if (hash.mileage_usage_item_id === 0) {
        delete hash.mileage_usage_item_id;
      }

      if (hash.generator_usage_item_id === 0) {
        delete hash.generator_usage_item_id;
      }

      if (hash.delivery_usage_item_id === 0) {
        delete hash.delivery_usage_item_id;
      }

      if (hash.rental_price_usage_item_id === 0) {
        delete hash.rental_price_usage_item_id;
      }

      if (hash.parent_id === 0) {
        delete hash.parent_id;
      }

      hash.is_child = Ember.isPresent(hash.parent_id);
      delete hash.price;
      delete hash.home;
      delete hash.features;
      delete hash.items;
      delete hash.insurance_plan_id;
      return super.normalize(typeClass, hash);
    }

    serialize(record, options) {
      const hash = super.serialize(record, options); // add the features

      if (!hash.features) {
        hash.features = {};
      }

      _rentalFeatures.default.forEach(feature => {
        hash.features[feature] = hash[feature];
        return delete hash[feature];
      });

      hash.insurance_plan_id = hash.insurance_plan || null;
      hash.active_price = {
        day: hash.active_price_day,
        week: hash.active_price_week,
        month: hash.active_price_month
      };
      hash.active_options = {
        instant_book: hash.active_instant_book,
        cancel_policy: hash.active_cancel_policy,
        cancel_policy_combo_bookings: hash.active_cancel_policy_combo_bookings
      };
      let priceObj = record.belongsTo('price');

      if (priceObj) {
        hash.price_day = priceObj.serialize().data.attributes.price;
      }

      hash.price = {
        day: hash.price_day,
        tax_rate: hash.tax_rate,
        security_deposit: hash.security_deposit,
        can_charge_security_deposit: hash.can_charge_security_deposit
      };
      hash.locale = {
        base_currency: hash.locale_base_currency,
        length_unit: hash.locale_length_unit,
        weight_unit: hash.locale_weight_unit,
        liquid_unit: hash.locale_liquid_unit,
        distance_unit: hash.locale_distance_unit
      };
      hash.vehicle = {
        make: hash.vehicle_make,
        model: hash.vehicle_model,
        trim: hash.vehicle_trim,
        current_mileage: hash.vehicle_current_mileage,
        current_generator: hash.vehicle_current_generator,
        year: hash.vehicle_year,
        length: hash.vehicle_length,
        vin: hash.vehicle_vin,
        license_plate: hash.vehicle_license_plate,
        license_plate_state: hash.vehicle_license_plate_state,
        license_plate_country: hash.vehicle_license_plate_country,
        replacement_value: hash.vehicle_replacement_value,
        gvwr: hash.vehicle_gvwr,
        amps: hash.vehicle_amps,
        dry_weight: hash.vehicle_dry_weight,
        height: hash.vehicle_height,
        width: hash.vehicle_width,
        length_with_hitch: hash.vehicle_length_with_hitch,
        box_length: hash.vehicle_box_length,
        class: hash.vehicle_class,
        unit_of_measure: hash.vehicle_unit_of_measure
      };
      hash.consignment = {
        owner_id: hash.vehicle_owner_id,
        pay_pct: hash.consigned_pay_pct,
        pay_pct_generator: hash.consigned_pay_pct_generator,
        pay_pct_mileage: hash.consigned_pay_pct_mileage,
        keep_pct_cc_fee: hash.consigned_pay_pct_cc_fee,
        keep_pct_insurance_fee: hash.consigned_pay_pct_insurance_fee
      };
      hash.settings = {
        show_renter_submitted_photos: hash.show_renter_submitted_photos
      };
      hash.home = hash.location;
      let tax_rate_ids = hash.tax_rates || [];
      hash.tax_rate_ids = tax_rate_ids.map(tr => parseInt(tr));
      delete hash.tax_rates; // delete hash.tax_rate_id;

      delete hash.vehicle_owner_id;
      delete hash.consigned_pay_pct;
      delete hash.consigned_pay_pct_generator;
      delete hash.consigned_pay_pct_mileage;
      delete hash.consigned_pay_pct_cc;
      delete hash.consigned_pay_pct_insurance_fee;
      delete hash.locale_base_currency;
      delete hash.locale_length_unit;
      delete hash.locale_weight_unit;
      delete hash.locale_liquid_unit;
      delete hash.locale_distance_unit;
      delete hash.location;
      delete hash.price_day;
      delete hash.price_month;
      delete hash.price_week;
      delete hash.vehicle_make;
      delete hash.vehicle_model;
      delete hash.vehicle_trim;
      delete hash.vehicle_year;
      delete hash.vehicle_width;
      delete hash.vehicle_box_length;
      delete hash.vehicle_length_with_hitch;
      delete hash.vehicle_height;
      delete hash.vehicle_license_plate;
      delete hash.vehicle_license_plate_state;
      delete hash.vehicle_replacement_value;
      delete hash.vehicle_current_mileage;
      delete hash.vehicle_current_generator;
      delete hash.vehicle_vin;
      delete hash.owner_identifier;
      delete hash.primary_image_id;
      delete hash.import_id;
      delete hash.show_renter_submitted_photos;
      return hash;
    }

  }

  _exports.default = RentalSerializer;
});
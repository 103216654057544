define("@outdoorsyco/ember-shared-data/components/state-by-country-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z5BLsw+S",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"not\",[[24,[\"value\"]]],null],[28,\"find-by\",[\"short\",[24,[\"value\"]],[24,[\"states\"]]],null]],null]],null,{\"statements\":[[4,\"select-field\",null,[[\"new\",\"allowCreate\",\"label\",\"formElementId\",\"options\",\"placeholder\",\"value\",\"searchField\",\"onChange\",\"onCreate\",\"showErrors\",\"renderInPlace\",\"error\",\"required\"],[[24,[\"new\"]],[24,[\"allowCreate\"]],[24,[\"label\"]],[24,[\"id\"]],[24,[\"groupedStates\"]],[24,[\"placeholder\"]],[28,\"find-by\",[\"short\",[24,[\"value\"]],[24,[\"states\"]]],null],\"short\",[28,\"action\",[[23,0,[]],\"setState\"],null],[28,\"action\",[[23,0,[]],\"setState\"],null],[24,[\"showErrors\"]],[24,[\"renderInPlace\"]],[24,[\"error\"]],[24,[\"isRequired\"]]]],{\"statements\":[[0,\"    \"],[7,\"strong\",true],[10,\"class\",\"state-code-option\"],[8],[1,[23,1,[\"short\"]],false],[9],[0,\" - \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input-field\",null,[[\"new\",\"label\",\"required\",\"value\",\"placeholder\"],[[24,[\"new\"]],[24,[\"label\"]],[24,[\"isRequired\"]],[24,[\"value\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/state-by-country-select/template.hbs"
    }
  });

  _exports.default = _default;
});
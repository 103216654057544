define("@outdoorsyco/ember-shared-data/utils/datadog-browser-rum", ["exports", "@datadog/browser-rum"], function (_exports, _browserRum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datadogBrowserRumInit = datadogBrowserRumInit;

  function datadogBrowserRumInit(config) {
    const {
      environment,
      enabled,
      applicationId,
      clientToken,
      serviceName,
      premiumSampleRate
    } = config.datadogBrowserRum;

    if (enabled) {
      _browserRum.datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        service: serviceName,
        env: environment,
        sampleRate: 100,
        premiumSampleRate: premiumSampleRate !== undefined ? premiumSampleRate : 1,
        trackInteractions: true,
        beforeSend: (event, context) => {
          let req = {};

          if (context && context.requestInit) {
            req = context.requestInit;
          }

          if (req && ['POST', 'PATCH'].includes(req.method)) {
            const FILTER_KEY = '[FILTERED]';
            let body = req.body || '{}';
            let json = JSON.parse(body);

            if (json) {
              const KEYS = ['password', 'credit_card_number', 'w9_user_data.social_security', 'card.number', 'card.cvc', 'card[number]', 'card[cvc]'];

              for (const k of KEYS) {
                let val = Ember.get(json, k);

                if (val) {
                  Ember.set(json, k, FILTER_KEY);
                }
              }

              context.requestInit.body = JSON.stringify(json);
            }
          }
        }
      });
    }
  }
});